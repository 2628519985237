import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getBusinessInfo } from "../../services/PreRegister";
import { companiesGetPlanMeta } from "../../services/CompaniesManagement";

import { SuccessMessage, ErrorMessage } from "../../utils/alerts";

import { B2BTheme } from "../../components/PreRegisterThemes/B2BTheme";
import { B2CTheme } from "../../components/PreRegisterThemes/B2CTheme";
import { Spinner } from "react-activity";
import { Colors } from "../../values/colors";
import FlexPanel from "../../components/FlexPanel";
import { Line } from "../../components/Line/styles";
import { CodeTheme } from "../../components/PreRegisterThemes/CodeTheme";

export const PreRegistrationLayoutManagement = () => {
  const [currentTheme, setCurrentTheme] = useState();
  const [businessInfo, setBusinessInfo] = useState(null)
  const { currentBusiness } = useParams();


  const { t } = useTranslation("common");

  useEffect(() => {
    getCurrentTheme()
  },[])

  const getCurrentTheme = async () => {
    try {
      const data = await getBusinessInfo(currentBusiness);

      if(!data) return
      setBusinessInfo(data)
      setCurrentTheme(data.theme)
    } catch (error) {
      ErrorMessage({ message: t("error-connecting-to-server") });
      console.warn(error);
    }
  };

  const render = () => {

    const themes = {
      b2b: <B2BTheme businessInfo={businessInfo} />,
      b2c: <B2CTheme businessInfo={businessInfo} />,
      code: <CodeTheme businessInfo={businessInfo} />,
    };
    
    const Component = themes[currentTheme]
    return Component
  }

  return <div>{currentTheme ? render() : 
    <Line alignItems="center" align="center" style={{height: '100vh'}}>
      <Spinner color={Colors.primaryBlue} size={30} style={{ marginLeft: 10 }} />
    </Line>
  }</div>;
};
