import React, { useContext, useEffect, useState } from "react";
import '../../../Shered/styles/global.css';
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Box } from "../../../components/Box";
import DataTable from "../../../components/DataTable";
import TextBox from "../../../components/TextBox";
import { Colors } from "../../../values/colors";
import { ActionButton } from '../../../components/ActionButton';

import {
  companiesGetPlanMeta,
  createOrUpdatePlanMeta,
  getPlansMetaList
} from "../../../services/CompaniesManagement";

import {
  ButtonContainer
} from "./styles";

import { ErrorMessage, SuccessMessage } from "../../../utils/alerts";
import { Text } from "../../../components/BaseComponents/styles";
import Switch from "../../../components/ToggleSwitch";
import { CompaniesManagementContext } from "../context/CompaniesManagement";
import InputDinamic from "./Input";

export const PlansManagement = () => {
  const [plansData, setPlansData] = useState([]);
  const [setOptions] = useOutletContext();
  const { data } = useContext(CompaniesManagementContext)
  const { t } = useTranslation("common");
  const valuesData = JSON.parse(localStorage.getItem('companiesData'));
  const [loading, setLoading] = useState()

  useEffect(() => {
    setOptions({
      start: [
        <Text
          color={"white"}
          weight={700}
          style={{ textDecoration: "underline" }}
        >
          {valuesData.plan_name}
        </Text>
      ]
    });
    return () => setOptions(null);
  }, []);

  function findTeleBalanceValue(data) {
    for (const item of data) {
        if (item.key === "tele_balance") {
            return item.value;
        }
    }
    return null;
  }

  function findPaidByValue(data) {
    for (const item of data) {
        if (item.key === "paid_by") {
            return item.value;
        }
    }
    return null;
  }

  function findDependentByValue(data) {
    for (const item of data) {
        if (item.key === "dependent_enabled") {
            return item.value;
        }
    }
    return null;
  }

  function customSort(a, b) {
    const order = ["professional_enabled", "tele_enabled", "tele_balance", "tele_credits", "recurrence_interval"];
    const aIndex = order.indexOf(a.key);
    const bIndex = order.indexOf(b.key);

    if (aIndex === -1 && bIndex === -1) {
      return 0;
    } else if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    } else {
      return aIndex - bIndex;
    }
  }

  const getAllPlanMeta = async () => {
    try {
      const plansMetaList = await getPlansMetaList();
      for (const item of plansMetaList) {
        item.value = item.default.length === 1 ? parseInt(item.default) : item.default;
        item.hideEdit = !['string', 'int'].includes(item.type) || ['tele_balance', 'pre_register_theme', 'paid_by', 'dependent_enabled'].includes(item.key);
      }
      setPlansData(plansMetaList);
      plansMetaList.sort(customSort);
      if(valuesData.plan_id) {
        let tele_balance_value = '';
        let payd_by_value = '';
        let dependent_limit = '';
        const getPlanMeta = await companiesGetPlanMeta(valuesData.plan_id);
        if(getPlanMeta.length > 0 ) {
          const updatedPlansData = plansMetaList.map((plan) => {
            const matchingResp = getPlanMeta.find((item) => item.key === plan.key);
            if (matchingResp) {
              return { ...plan, value: matchingResp.value };
            }
            return { ...plan, value: 0 };
          });
          setPlansData(updatedPlansData);
          
          tele_balance_value = findTeleBalanceValue(updatedPlansData);
          payd_by_value = findPaidByValue(updatedPlansData);
          dependent_limit = findDependentByValue(updatedPlansData);
        } else {
          tele_balance_value = findTeleBalanceValue(plansMetaList);
          payd_by_value = findPaidByValue(plansMetaList);
          dependent_limit = findDependentByValue(plansMetaList);
        }
        handleChangeInputs({ target: { value: tele_balance_value } }, { key: 'tele_balance'});
        handleChangeInputs({ target: { value: payd_by_value === 0 ? 'b2b' : payd_by_value } }, { key: 'paid_by'});
        handleChangeInputs({ target: { value: dependent_limit } }, { key: 'dependent_enabled'});
      }
    } catch (error) {
      console.warn(error.message);
    }
    
    
  }

  useEffect(() => {
    getAllPlanMeta();
  }, []);

  const handleChange = (event, data) => {
    if (data.key === 'tele_enabled' && data.value === 0) {
      handleChangeInputs({ target: { value: 'user' } }, { key: 'tele_balance'});
    } 
    setPlansData(state => {
      return state.map(item => {
        
        let _item = {...item}
        

        if (item.id === data.id) {
          return {
            ..._item,
            value: data.value === 1 ? 0 : 1
          };
        }
        return _item;
      });
    }); 
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
    
      const plansMeta = plansData.map(item => ({
        ...item,
        plan_id: valuesData.plan_id
      }));

      plansMeta.map(objeto => {
        if (objeto.default === "b2b" && objeto.value === 0) {
          objeto.value = 'b2b'
        }
        if (objeto.key === "dependent_enabled" && objeto.value === 0) {
          plansMeta.forEach(obj => {
              if (obj.key === "dependent_limit") {
                  obj.value = 0;
              }
          });
        }
        if (objeto.key === "tele_enabled" && objeto.value === 0) {
            plansMeta.forEach(obj => {
                if (obj.parent_key === "tele_enabled" && (obj.key === "tele_balance" || obj.key === "tele_credits")) {
                    obj.value = 0;
                }
            });
        }
        return objeto;
      });

      const res = await createOrUpdatePlanMeta(plansMeta);
      if(res.status === 200) {
        SuccessMessage({ message: t("save-feature") });
      } else {
        ErrorMessage({ message: t("erro-save") });
      }
    } catch (error) {

      ErrorMessage({ message: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (data, index) => {
    const _plansData = [...plansData]
    _plansData[index] = data

    setPlansData(_plansData);
  }

  const handleChangeInputs = (e, row) => {
    const { name, value } = e.target;

    setPlansData(state => state.map(item => {
      if (item.key === name || item.key === row.key) {
          return { ...item, value: value };
      }
      return item;
    }));
    
  };


  return (
    <>
      <Box noPadding={true} noHeader>
        <DataTable
          collumns={[
            {
              title: "Funcionalidade do plano",
              key: "name",
              field: TextBox,
              render: (row, index) => {
                return (
                  <span key={`${row.name}${index}`}>{row.name}</span>)
              },
            },
            {
              title: "",
              key: "value",
              editable: true,
              field: TextBox,
              render: (row) => {
                if (row.type === "string" || row.type === "int") {
                  const isDisabled = (
                    (row.parent_key && plansData.find(item => item.key === row.parent_key)?.value === 0)
                  );

                  switch(row.key) {
                    case 'tele_balance':
                      return (
                        <select
                            id={row.key} 
                            name={row.key}
                            value={isDisabled ? '' : (row.value !== 0 ? row.value : plansData.find(item => item.key === row.key)?.value)}
                            onChange={(e) => handleChangeInputs(e, row)}
                            disabled={isDisabled}
                            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                            <option key="user" value="user">Creditos por usuário</option>
                            <option key="pool" value="pool">Creditos compartilhados</option>
                        </select> 
                        );
                    case 'pre_register_theme':
                      return (
                        <select
                            id={row.key} 
                            name={row.key}
                            value={row.value}
                            onChange={(e) => handleChangeInputs(e, row)}
                            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                            <option key="b2b" value="b2b">Para Empresas</option>
                            <option key="b2c" value="b2c">Para Clientes</option>
                            <option key="code" value="code">Código de confirmação</option>
                        </select> 
                        );
                    case 'paid_by':
                      return (
                        <select
                            id={row.key} 
                            name={row.key}
                            value={row.value}
                            onChange={(e) => handleChangeInputs(e, row)}
                            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                            <option key="user" value="user">Usuário</option>
                            <option key="b2b" value="b2b">Empresa</option>
                        </select> 
                        );
                    case 'recurrence_interval':
                      const recEnabled = plansData.find(item => item.key === 'tele_balance')?.value === 'user';
                      return (
                        <select
                          id={row.key}
                          name={row.key}
                          value={recEnabled ? row.value : 0}
                          disabled={!recEnabled}
                          onChange={(e) => handleChangeInputs(e, row)}
                          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                          <option key="0" value="0">Mensal</option>
                          <option key="1" value="1">Anual</option>
                        </select>
                      );
                    default: 
                      return (
                        <InputDinamic 
                          type={row.type === "string" ? "text" : "number"} 
                          name={row.key}
                          id={row.key}
                          value={isDisabled ? '' : (row.value !== 0 ? row.value : plansData.find(item => item.key === row.key)?.value)}
                          onChange={(e) => handleChangeInputs(e, row)}
                          disabled={true}
                        />
                      );
                  }
            
                } else {
                  const isDisabled = (
                    (row.parent_key && plansData.find(item => item.key === row.parent_key)?.value === 0)
                  );
                  if(isDisabled) row.value = 0;

                  return (
                    <Switch
                      id={row.id}
                      key={row.id}
                      name={row.key}
                      toggled={row.value === 1 || row.value === 3}
                      onChange={(e) => handleChange(e, row)}
                      disabled={isDisabled}
                    />
                  )
                }
              },
             },
             {
                 title: "",
                 key: "type",
                 render: (row, index) => {
                  return (
                    <div key={`${index}${row.name}`} className="sm:col-span-2" style={{ display: 'flex' , alignItems: 'center', justifyContent: 'space-evenly', marginRight: '350px'}}>
                    {' '}
                    </div>
                  )
                 }
              }
          ]}
          data={plansData}
          // defaultActions={false}
          hideDelete
          onUpdate={
            (data, index) => {
              handleUpdate(data, index)
            }
          }
          withPagination={false}
        />
        <ButtonContainer>
        <ActionButton color={Colors.defaultBlue} isLoading={loading} onClick={(e) => handleSubmit(e)} icon={<Plus size={18} />}>{t('save-plans')}</ActionButton>
        </ButtonContainer>
      </Box>
          
    </>
  );
};
